import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Logo from './assets/Albuddy.png';

const IBMathPrepForm = () => {
  const initialFormData = {
    name: '',
    email: '',
    role: 'Parent',
    preferredCommunication: '',
    communicationDetail: '', // To store the contact info (e.g., Kakao ID, Messenger handle)
    parentConcerns: '',
    parentHelp: [],
    otherHelp: '',
    parentGoals: '',
    studentChallenges: '',
    studentGoals: '',
    studentMissing: [],
    futureVision: '',
    careerImpact: '',
    consultation: 'Yes',
    timestamp: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [submittedName, setSubmittedName] = useState('User');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSection, setFormSection] = useState('Parent');

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked
          ? [...prevState[name], value]
          : prevState[name].filter((item) => item !== value),
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      if (name === 'role') {
        setFormSection(value);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      parentHelp: [...formData.parentHelp, formData.otherHelp].filter(Boolean),
      timestamp: new Date().toISOString(),
    };

    try {
      setSubmittedName(formData.name || 'User');
      await addDoc(collection(db, 'IBMathPrepFormResponses'), updatedFormData);

      setFormSubmitted(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => setFormSubmitted(false), 10000);

      setFormData(initialFormData);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const isParent = formSection === 'Parent';

  return (
    <div className="max-w-3xl mx-auto bg-white p-8 shadow-lg rounded-lg space-y-8">
      {formSubmitted && (
        <div className="p-4 mb-4 text-green-700 bg-green-100 rounded-lg text-center">
          Thank you, {submittedName}! Your feedback has been submitted successfully.
        </div>
      )}

      <div className="text-center mb-8">
        <img
          src={Logo}
          alt="IB Math Prep Header"
          className="mx-auto w-full h-40 object-contain rounded-lg"
        />
        <h1 className="text-4xl font-semibold text-[#4A90E2]">Feedback Form</h1>
        <p className="text-lg text-gray-500">
          Hey, it's Marcus here! Thank you so much for your interest in Albuddy. I'm excited to hear your feedback and stay connected—your input means a lot, and you might just see some of your ideas in action!
        </p>
      </div>

      {/* Disclaimer */}
      <p className="text-center text-sm text-gray-500">
        All information is kept safe and confidential. Please do not include sensitive information such as passwords or private security details.
      </p>

      <form onSubmit={handleSubmit} className="space-y-12">
        <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
          <h2 className="text-2xl font-semibold text-[#4A90E2]">Basic Information<span className="text-red-600">*</span></h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">Your Name (Parent or Student)</label>
              <input
                name="name"
                type="text"
                value={formData.name}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Enter your name"
                required
              />
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">Email Address</label>
              <input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Enter your email"
                required
              />
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">Are you a Parent or a Student?</label>
              <select
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
              >
                <option value="Parent">Parent</option>
                <option value="Student">Student</option>
              </select>
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">Preferred Communication Method</label>
              <select
                name="preferredCommunication"
                value={formData.preferredCommunication}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                required
              >
                <option value="">Select form of communication</option>
                <option value="Phone Number">Phone Number</option>
                <option value="KakaoTalk">KakaoTalk</option>
                <option value="Facebook Messenger">Facebook Messenger</option>
                <option value="WhatsApp">WhatsApp</option>
                <option value="WeChat">WeChat</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">Enter your ID or Username</label>
              <input
                name="communicationDetail"
                type="text"
                value={formData.communicationDetail}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Enter your ID or Username"
                required
              />
            </div>
          </div>
        </div>

        {isParent ? (
          <>
            <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
              <label className="block text-lg font-medium text-gray-600 mb-2">What are your primary concerns or goals regarding your child's IB, essay writing (IA), or Math education?<span className="text-red-600">*</span></label>
              <textarea
                name="parentConcerns"
                value={formData.parentConcerns}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Share your concerns"
              />
            </div>

            <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
              <h2 className="text-2xl font-semibold text-[#4A90E2]">What does your child need the most help with to succeed?(Check all that apply)<span className="text-red-600">*</span></h2>
              <div className="flex flex-col space-y-4">
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="parentHelp"
                    value="IB exam preparation"
                    onChange={handleInputChange}
                    className="form-checkbox h-6 w-6 text-[#4A90E2] rounded focus:ring focus:ring-[#4A90E2]"
                  />
                  <span className="text-lg text-gray-700">Preparation for the IB exam</span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="parentHelp"
                    value="Advanced Math"
                    onChange={handleInputChange}
                    className="form-checkbox h-6 w-6 text-[#4A90E2] rounded focus:ring focus:ring-[#4A90E2]"
                  />
                  <span className="text-lg text-gray-700">Mastery of advanced Math concepts</span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="parentHelp"
                    value="IA Essay Writing"
                    onChange={handleInputChange}
                    className="form-checkbox h-6 w-6 text-[#4A90E2] rounded focus:ring focus:ring-[#4A90E2]"
                  />
                  <span className="text-lg text-gray-700">Help with IA (Internal Assessment) essay writing</span>
                </label>

                <input
                  name="otherHelp"
                  type="text"
                  value={formData.otherHelp}
                  onChange={handleInputChange}
                  className="block w-2/3 border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg mt-4"
                  placeholder="Other (please specify)"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
              <label className="block text-lg font-medium text-gray-600 mb-2">Which subject do you find most challenging: IB exam prep, IA essay writing, or Math? Why?<span className="text-red-600">*</span></label>
              <textarea
                name="studentChallenges"
                value={formData.studentChallenges}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Share your challenges"
              />
            </div>

            <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
              <label className="block text-lg font-medium text-gray-600 mb-2">What are your goals in preparing for the IB exam, IA essays, or Math?<span className="text-red-600">*</span></label>
              <textarea
                name="studentGoals"
                value={formData.studentGoals}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Share your goals"
              />
            </div>
          </>
        )}

        <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
          <label className="block text-lg font-medium text-gray-600 mb-2">
            {isParent
              ? 'How do you envision your child succeeding after improving their skills in IB, IA, or Math?'
              : 'How do you envision succeeding after improving your skills in IB, IA, or Math?'}
              <span className="text-red-600">*</span>
          </label>
          <textarea
            name="futureVision"
            value={formData.futureVision}
            onChange={handleInputChange}
            className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
            placeholder={isParent ? 'Share your vision for your child' : 'Share your vision'}
            required
          />
        </div>

        <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
          <label className="block text-lg font-medium text-gray-600 mb-2">
            {isParent
              ? 'Would you like to schedule a free consultation to discuss your child’s academic goals?'
              : 'Would you like to schedule a free consultation to discuss your academic goals?'}
              <span className="text-red-600">*</span>
          </label>
          <select
            name="consultation"
            value={formData.consultation}
            onChange={handleInputChange}
            className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
            required
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>


        <button
          type="submit"
          className="w-full bg-[#4A90E2] text-white rounded-lg p-5 text-lg hover:bg-[#357ABD] focus:outline-none focus:ring focus:ring-[#357ABD]"
        >
          Submit Form
        </button>
      </form>
    </div>
  );
};

export default IBMathPrepForm;
